import React from "react";
import { BackTop } from "antd";
import { Image } from "react-bootstrap";
import "./BackToTop.scss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default function BackToTop() {
  return (
    <div className="backtotop">
      <BackTop
        className="backtotop-cover"
        visibilityHeight={window.outerHeight}
      >
        <div className="backtotop-archor">
          <ExpandLessIcon />
        </div>
      </BackTop>
    </div>
  );
}

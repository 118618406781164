
// export const base_url = "http://127.0.0.1:8000"
// export const oss_url = "http://127.0.0.1:8000"
// export const tour_manager_login='http://localhost:3001/'

//Dev Staging ENV 
// export const base_url = "https://dev-api.otma.org.my";
// export const tour_manager_login='https://dev-portal.otma.org.my/login'
// export const oss_url = "https://dev-api.otma.org.my"

//Production ENV
export const base_url = "https://api.otma.org.my"
export const tour_manager_login='https://portal.otma.org.my/login'
export const oss_url = "https://api.otma.org.my"

export const client_id = 1

export const client_secret = "4ulAauwuB00YKlQoPgGkn6aKuoTIWj9kOf4rzHI7"







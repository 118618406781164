import React from "react";
import { Row, Col, Image, Navbar, NavLink } from "react-bootstrap";
import "./Footer.scss";
import "./RespFooter.scss";

function Footer() {
  return (
    <div className="footer-container text-center">
      <div className="py-3">
        <div className="pt-3">
          <Image
            className="footer-logo"
            src={require("../../images/header-logo.png")}
          />
        </div>
        <div className="pb-4 pt-3">
          <h2 className="text-white title">
            OutBound Tour Managers Association's
          </h2>
        </div>
        <div className="pb-4">
          <div className="d-flex flex-row mb-3 flex-wrap text-white justify-content-center">
            <div className="px-3">
              <a className="footer-link" href="/">
                HOME
              </a>
            </div>
            <div className="px-3">
              <a className="footer-link" href="/tourmanager">
                TOUR MANAGERS
              </a>
            </div>
            <div className="px-3">
              <a className="footer-link" href="/gallery">
                PHOTO GALLERY
              </a>
            </div>
            <div className="px-3">
              <a className="footer-link" href="https://www.facebook.com/OTMA-cares-275839729187354/">
                CHARITY
              </a>
            </div>
            <div className="px-3">
              <a className="footer-link" href="/contactUs">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
        <div className="text-white pb-4">SOCIAL MEDIA:</div>
        <div className="pb-5">
          <div className="d-flex flex-row flex-wrap justify-content-center">
            <div className="px-3">
              <a className="sns-link" href="https://www.facebook.com/OTMA-111255873897956">
                <Image
                  className="logo"
                  src={require("../../images/fb-logo.png")}
                />
              </a>
            </div>
            <div className="px-3">
              <a className="sns-link" href="https://www.instagram.com/otma_tourmanagers/">
                <Image
                  className="logo"
                  src={require("../../images/insta-logo.png")}
                />
              </a>
            </div>
            <div className="px-3">
              <a className="sns-link" href="#">
                <Image
                  className="logo"
                  src={require("../../images/telegram-logo1.png")}
                />
              </a>
            </div>
            <div className="px-3">
              <a className="sns-link" href="#">
                <Image
                  className="logo"
                  src={require("../../images/youtube-logo.png")}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="text-white-50">
          <p>
            <small>Copyright © 2024 OTMA @ Pan Fareast</small>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { Suspense, useEffect } from "react";
import "./App.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import TopPage from "./components/toppage/TopPage";
import Footer from "./components/footer/Footer";
import BackDropScreen from "./components/backdrop/Backdrop";
import BackToTop from "./components/BackToTop.js";
import { setBaseUrl } from "./utilities/helper";

// Main Router
const MainRouter = React.lazy(() => import("./router/Main"));

function App() {

    useEffect(() => {
        setBaseUrl()
    },[])
    return (
        <>
        <Suspense fallback={<BackDropScreen />}>
            <TopPage />
            <BackToTop />
            <MainRouter />
            <Footer />
        </Suspense>
        </>
    );
}

export default App;

import React from "react";
import { Drawer, makeStyles, Link } from "@material-ui/core";
import { Image } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { NavLink } from "react-router-dom";
import { tour_manager_login } from "../../utilities/env.js";
import "./SlidePane.scss";
import "./RespSlidePane.scss";

const drawerWidth = "100%";

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function SlidePane({ setSlidePaneOpen, slidePaneOpen }) {
  const classes = useStyles();

  return (
    <Drawer
      className={(classes.drawer, "mobileslide-drawer")}
      variant="persistent"
      anchor="right"
      open={slidePaneOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={(classes.drawerHeader, "mobileslide-header")}>
        <a href="/" className="mobileslide-logo">
          <Image
            className="mobileslide-png"
            src={require("../../images/visiblelogo.png")}
          />
        </a>
        <Link
          className="mobileslide-cross"
          underline="none"
          onClick={() => setSlidePaneOpen(false)}
        >
          <ImCross />
        </Link>
      </div>
      <div className="mobileslide-body">
        <div className="mobileslide-section">
          <NavLink
            to="/"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
            exact
          >
            Home
          </NavLink>
        </div>
        <div className="mobileslide-section">
          <NavLink
            to="/about"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
            exact
          >
            About Us
          </NavLink>
        </div>
        <div className="mobileslide-section">
          <NavLink
            to="/tourmanager"
            href="/about"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
            path={["/tourmanager", "/tourmanager/:id"]}
          >
            Tour Manager List
          </NavLink>
        </div>
        <div className="mobileslide-section">
          <NavLink
            to="/news"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
            path={["/news", "/news/details"]}
          >
            NEWS & ARTICLES
          </NavLink>
        </div>
        <div className="mobileslide-section">
          <NavLink
            to="/gallery"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
            path={["/gallery", "/gallery/:id"]}
          >
            photo gallery
          </NavLink>
        </div>
        <div className="mobileslide-section">
          <a
            href="https://www.facebook.com/OTMA-Charity-Page-275839729187354/"
            onClick={() => setSlidePaneOpen(false)}
          >
            CHARITY
          </a>
        </div>
        <div className="mobileslide-section">
          <NavLink
            to="/contactUs"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
          >
            Contact Us
          </NavLink>
        </div>
        <div className="mobileslide-actions">
          <NavLink
            className="signup"
            to="/register"
            activeClassName="active"
            onClick={() => setSlidePaneOpen(false)}
          >
            Sign Up
          </NavLink>
          <a
            className="login"
            href={tour_manager_login}
            onClick={() => setSlidePaneOpen(false)}
          >
            Member Login
          </a>
        </div>
      </div>
    </Drawer>
  );
}

import React from "react";
import "./Backdrop.scss";
import Loading from "../../images/loading.gif";

export default function BackDropScreen() {
  return (
    <div className="backdrop-cover">
      <img src={Loading} />
    </div>
  );
}
